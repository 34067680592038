<template>
  <div>
    <form class="mb-2" inline>
      <div class="d-inline-block">
        <small>조회 기간</small><br/>
        <date-from-to :from.sync="form.list.dateFrom" :to.sync="form.list.dateTo" :init="'1 month'"></date-from-to>
      </div>
      <div class="ml-2 d-inline-block">
        <small>기본 로그수</small><br/>
        <b-form-input class="text-center w-70px" title="한 번에 가져올 로그 수" v-model.number="form.list.limit" @keypress.enter="list()"></b-form-input>
      </div>
    </form>
    <div class="mb-2 clearfix">
      <div class="pull-left">
        <b-button variant="primary" @click="list" :disabled="busy.list">
          <i class="fa fa-search"></i> 검색
          <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
        </b-button>
      </div>
      <div class="pull-right">
        <xlsx :types="['xlsx', 'csv']" :preFunc="preDown" :data="xlsx.selected" :labels="xlsx.labels" :keys="xlsx.keys" name="AuthLogs"></xlsx>
      </div>
    </div>
    <div class="mb-1">
      <div class="d-inline">조회된 로그 : {{items.list.length}} 건</div>
      <b-button v-if="hasMore.list" size="sm" class="ml-2" style="vertical-align: baseline" variant="light" @click="list(true)" :disabled="busy.listmore">
        More
        <b-spinner class="ml-1" small v-if="busy.listmore"></b-spinner>
      </b-button>
    </div>
    <b-row>
      <b-col cols="12" lg="12">
        <htb ref="htb" v-model="items.list" :config="htbConfig" height="calc(100vh - 370px)"></htb>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
</style>

<script>

import Vue from "vue";
import xlsx from '@/views/modules/Xlsx.vue'
import Handsontable from "handsontable";

export default {
  name: 'PrivateLogs',
  title: '개인정보 접근 기록',
  components: {xlsx},
  data() {
    return {
      defaultForm: {
        list: {
          dateFrom: this.$moment().add(-1, 'month').format('YYYY-MM-DD'),
          dateTo: this.$moment().format('YYYY-MM-DD'),
          limit: 1000,
          skip: 0,
        },
      },
      form: {
        list: {},
      },
      lastBody: {list: {}},
      items: {list: []},
      busy: {list: false, listmore: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      xlsx: {
        selected: [],
        keys: [],
        labels: [],
      },

      htbConfig: {
        fields: [
          {key: 'route', name: '경로'},
          {key: '_uid', name: '실행ID'},
          {key: '_name', name: '실행유저'},
          {key: '_dt', name: '실행일시'},
          {key: '_ip', name: '실행IP'},
          {key: 'type', name: '종류'},
          {key: 'reason', name: '사유'},
          {key: 'count', name: '개인정보 건수'},
          {key: 'form_length', name: '조회조건 길이'},
          {
            key: 'form', name: '조회조건', width: 60, readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
              // console.log(this.listAllProperties(cellProperties), cellProperties.className);
              // console.log(td.className, cellProperties.className, td);
              td.className = cellProperties.className;
              Handsontable.dom.empty(td);
              const onclick = () => this.$modal.show({title: 'JSON', item: this.items.list[cellProperties.row].form, type: 'json'});

              // A - html 교체로 생성
              td.innerHTML = `<button class="btn btn-light btn-sm">상세</button>`;
              td.firstChild.onclick = onclick;

              // B - element 생성 후 추가
              // const btn = document.createElement('BUTTON');
              // btn.className = 'btn btn-light btn-sm';
              // btn.innerText = '보기';
              // btn.onclick = onclick;
              // td.appendChild(btn);

              return td;
            }
          },
        ],
        settings: {
          contextMenu: true,
          filters: true,
          dropdownMenu: true,
        }
      }
    }
  },
  created() {
    Vue.set(this.form, 'list', this.$utils.clone(this.defaultForm.list));
    Vue.set(this.xlsx, 'keys', this.htbConfig.fields.map(e => e.key));
    Vue.set(this.xlsx, 'labels', this.htbConfig.fields.map(e => e.name));
  },
  mounted() {
    this.list();
  },
  methods: {
    async list(more) {
      await this.$api.postTable(this, '/user/logs/privateLogs', this.form.list, {more, fnAssign: this.assignTableData});
      this.render();
    },
    assignTableData(e) {
      e.form_length = JSON.stringify(e.form).length;
      // Object.entries(e).forEach(([k, v]) => {
      //   if (typeof v === 'object') {
      //     e[k] = JSON.stringify(v);
      //   }
      // });
    },
    render() {
      setTimeout(() => this.$refs.htb.hotInstance.render(), 0);
    },
    preDown() {
      this.xlsx.selected = this.items.list;
      return true;
    },
  }
}
</script>
