<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab title="개인정보 접근 로그">
        <private-logs></private-logs>
      </b-tab>
      <b-tab title="권한 로그">
        <auth-logs v-if="loaded[1]"></auth-logs>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import PrivateLogs from './PrivateLogs.vue'
import AuthLogs from './AuthLogs.vue'

export default {
  name: 'AuditLogs',
  title: '감사 로그',
  components: {PrivateLogs, AuthLogs},
  data() {
    return {
      tabIndex: 0,
      loaded: {}, // {[tabIndex]: true} - lazy loading
    }
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
}
</script>
